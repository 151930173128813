"use client";

import { useCallback, useEffect, useState } from "react";

import { DatePicker as DatePickerWithRange, type DateRange } from "@revana/ui";
import { parseISO } from "date-fns";

import { useDateRangeQuery } from "@web/hooks";

const DateRangeSelector = () => {
  const { setDateRange, dateRange } = useDateRangeQuery();
  const [localDateRange, setLocalDateRange] = useState<DateRange | undefined>();

  const onSelectDate = useCallback(
    (date: DateRange | undefined) => {
      setLocalDateRange(date);
    },
    [setLocalDateRange]
  );

  const onCalendarClose = useCallback(() => {
    if (!localDateRange?.from) return;

    const dateRangeTo = localDateRange.to ?? localDateRange.from;

    void setDateRange({ from: localDateRange.from, to: dateRangeTo });
  }, [localDateRange, setDateRange]);

  useEffect(() => {
    setLocalDateRange({
      from: parseISO(dateRange.from),
      to: parseISO(dateRange.to),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatePickerWithRange
      date={localDateRange}
      setDate={onSelectDate}
      onClose={onCalendarClose}
    />
  );
};

export default DateRangeSelector;
