"use client";
import React, { use, useMemo } from "react";

import { type RouterOutputs } from "@revana/api";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
  Icon,
  type IIconName,
  Skeleton,
  Spinner,
} from "@revana/ui";
import CountUp from "react-countup";

import { useDateRangeQuery } from "@web/hooks";
import { api } from "@web/trpc/client";
import { calculateDateRangeDifference } from "@web/utils";

interface StatCardsProps {
  initialData: Promise<RouterOutputs["store"]["getOutreachAnalytics"]>;
}

const StatCard: React.FC<{
  label: string;
  value: number;
  valuePrefix?: string;
  icon: IIconName;
  isFetching: boolean;
  subtitle?: string;
}> = ({ label, subtitle, value, valuePrefix, icon, isFetching }) => {
  return (
    <Card>
      <CardHeader>
        <div className="flex w-full items-center justify-between">
          <CardDescription className="font-bold">{label}</CardDescription>
          {isFetching ? (
            <Spinner width="16" />
          ) : (
            <Icon className="h-5 w-5" name={icon} />
          )}
        </div>
        <CardTitle>
          <CountUp
            decimals={0}
            duration={1}
            end={value}
            prefix={valuePrefix}
            separator=","
            start={0}
          />
        </CardTitle>
        {subtitle && <CardDescription>{subtitle}</CardDescription>}
      </CardHeader>
    </Card>
  );
};

const StatCards: React.FC<StatCardsProps> = ({ initialData }) => {
  const { dateRange, isEqual } = useDateRangeQuery();

  const initialAnalytics = use(initialData);

  const {
    data: analytics,
    isFetching,
    isFetchedAfterMount,
  } = api.store.getOutreachAnalytics.useQuery(dateRange, {
    placeholderData: (data) => data ?? initialAnalytics,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const isRefetching = useMemo(() => {
    return (
      (!isEqual(dateRange, initialAnalytics.metadata) && isFetching) ||
      (isFetching && isFetchedAfterMount)
    );
  }, [
    dateRange,
    initialAnalytics.metadata,
    isEqual,
    isFetching,
    isFetchedAfterMount,
  ]);

  if (!analytics) {
    return <StatCardsFallback />;
  }

  const revenue = (analytics.revenue.currentPeriod / 100).toFixed(2) ?? 0;
  const revenuePercentageChange = Number(
    analytics.revenue.percentageChange.toFixed(1) ?? 0
  );

  const abandonedCheckouts = analytics.abandonedCheckouts.currentPeriod ?? 0;
  const abandonedCheckoutsPercentageChange = Number(
    analytics.abandonedCheckouts.percentageChange.toFixed(1) ?? 0
  );

  const outreachAttempts = analytics.outreachAttempts.currentPeriod ?? 0;
  const outreachAttemptsPercentageChange = Number(
    analytics.outreachAttempts.percentageChange.toFixed(1) ?? 0
  );

  const checkoutsRecovered = analytics.checkoutsRecovered.currentPeriod ?? 0;
  const checkoutsRecoveredPercentageChange = Number(
    analytics.checkoutsRecovered.percentageChange.toFixed(1) ?? 0
  );

  const dateRangeDifference = calculateDateRangeDifference(
    analytics.currentPeriod,
    analytics.previousPeriod
  );

  const revenueSubtitle = `${revenuePercentageChange > 0 ? "+" : ""}${revenuePercentageChange}% from ${dateRangeDifference}`;
  const abandonedCheckoutsSubtitle = `${abandonedCheckoutsPercentageChange > 0 ? "+" : ""}${abandonedCheckoutsPercentageChange}% from ${dateRangeDifference}`;
  const outreachAttemptsSubtitle = `${outreachAttemptsPercentageChange > 0 ? "+" : ""}${outreachAttemptsPercentageChange}% from ${dateRangeDifference}`;
  const checkoutsRecoveredSubtitle = `${checkoutsRecoveredPercentageChange > 0 ? "+" : ""}${checkoutsRecoveredPercentageChange}% from ${dateRangeDifference}`;

  return (
    <div className="grid grid-cols-4 gap-8">
      <StatCard
        icon="dollar-sign"
        isFetching={isRefetching}
        label="Revenue Recovered"
        subtitle={revenueSubtitle}
        value={Number(revenue)}
        valuePrefix="$"
      />
      <StatCard
        icon="shopping-cart"
        isFetching={isRefetching}
        label="Abandoned Checkouts"
        subtitle={abandonedCheckoutsSubtitle}
        value={abandonedCheckouts}
      />
      <StatCard
        icon="headset"
        isFetching={isRefetching}
        label="Outreach Attempts"
        subtitle={outreachAttemptsSubtitle}
        value={outreachAttempts}
      />
      <StatCard
        icon="shopping-cart"
        isFetching={isRefetching}
        label="Checkouts Recovered"
        subtitle={checkoutsRecoveredSubtitle}
        value={checkoutsRecovered}
      />
    </div>
  );
};

const StatCardFallback: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Card>
      <CardHeader>
        <div className="flex w-full items-center justify-between">
          <CardDescription className="font-bold text-black">
            {label}
          </CardDescription>
          <Skeleton className="h-5 w-5 rounded-full" />
        </div>
        <Skeleton className="mt-4 h-10 w-1/2" />
        <Skeleton className="mt-4 h-4 w-1/2" />
      </CardHeader>
    </Card>
  );
};
export const StatCardsFallback = () => {
  return (
    <div className="grid grid-cols-4 gap-8">
      <StatCardFallback label="Revenue Recovered" />
      <StatCardFallback label="Abandoned Checkouts" />
      <StatCardFallback label="Outreach Attempts" />
      <StatCardFallback label="Checkouts Recovered" />
    </div>
  );
};

export default StatCards;
