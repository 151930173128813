import { isKeyIn, isObject } from "@web/utils/type-utils";

const isMergebleObject = (item: unknown): item is object => {
  return isObject(item) && !Array.isArray(item);
};

export function mergeDeep<TBase extends object, TSource extends object>(
  base: TBase,
  source: TSource
): TBase & TSource {
  /* Merge two dictionaries deeply. 

  The standard {...dict1, ...dict2} merge is a shallow merge so only the top level keys
  are merged. As in dict1 = {a: {b: 2}} and dict2 = {a: {c: 3, d:4}}.

  {...dict1, ...dict2} === {a: {c: 3, d:4}}
  mergeDeep(dict1,dict2) === {a: {b:2, c: 3, d:4}}

  Args:
    base: The base dict
    source: the incoming dict, which will have precedence for overwriting
  
  Returns:
    merged dict
  */

  const output = { ...base } as TBase & TSource;

  if (isMergebleObject(base) && isMergebleObject(source)) {
    Object.keys(source).forEach((key) => {
      const sourceValue = source[key];
      if (isObject(sourceValue)) {
        const baseValue = isKeyIn(base, key) ? base[key] : undefined;
        const areValuesMergeable =
          isMergebleObject(baseValue) && isMergebleObject(sourceValue);

        if (!baseValue || !areValuesMergeable) {
          Object.assign(output, { [key]: sourceValue });
        } else if (isObject(baseValue)) {
          Object.assign(output, { [key]: mergeDeep(baseValue, sourceValue) });
        }
      } else {
        Object.assign(output, { [key]: sourceValue });
      }
    });
  }

  return output;
}
